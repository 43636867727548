import * as React from 'react';
import { View } from 'react-native';
import Markdown from 'react-native-markdown-renderer';
import { mdStyles } from './App';
var zipcodes = require('zipcodes');

export const PP = ({  }: { }) => {

  const copy = `
  Privacy Policy
  ==============
  
  Last revised on Oct. 26, 2020
  
  ### The Gist
  
  PopSale may collect certain non-personally identifying information about you as you use our sites. We may use this data to better understand our users. We can also publish this data, but the data will be about a large group of users, not individuals.
  
  We will also ask you to provide personal information and use cookies.
  
  That's the basic idea, but you must read through the entire Privacy Policy below and agree with all the details before you use the Service.
  
  ### Reuse
  
  This document is based upon the [Automattic Privacy Policy](http://automattic.com/privacy/) and is licensed under [Creative Commons Attribution Share-Alike License 2.5](http://creativecommons.org/licenses/by-sa/2.5/). Basically, this means you can use it verbatim or edited, but you must release new versions under the same license and you have to credit Automattic somewhere (like this!). Automattic is not connected with and does not sponsor or endorse PopSale or its use of the work.
  
  "PopSale" is a Sole Proprietorship based in westerrn Massachusetts. The "Service" includes all services PopSale makes available including but not limited to the web sites [popsale.co], [www.popsale.co], our blog, our API, and any other software, sites, and / or services offered by PopSale. "Content" means all content generated by PopSale on your behalf (including metric data).  It is PopSale's policy to respect your privacy regarding any information we may collect while operating the Service.
  
  ### Questions
  
  If you have question about this Privacy Policy, please contact us at support@popsale.co
  
  ### Visitors
  
  Like most website operators, PopSale collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. PopSale's purpose in collecting non-personally identifying information is to better understand how PopSale's visitors use its website. From time to time, PopSale may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of the Service.
  
  PopSale also collects potentially personally-identifying information like Internet Protocol (IP) addresses. PopSale does not disclose such information, other than under the same circumstances that it uses and discloses personally-identifying information, as described below. We may also collect and use IP addresses to block users who violated our Terms of Service.
  
  ### Gathering of Personally-Identifying Information
  
  Certain visitors to PopSale's services choose to interact with PopSale in ways that require PopSale to gather personally-identifying information. The amount and type of information that PopSale gathers depends on the nature of the interaction. PopSale does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain Service-related activities.
  
  Additionally, some interactions may ask for optional personal information. For instance, when posting a comment, may provide a website that will be displayed along with a user's name when the comment is displayed. Supplying such personal information is completely optional and is only displayed for the benefit and the convenience of the user.
  
  ### Aggregated Statistics
  
  PopSale may collect statistics about the behavior of visitors to the Service. PopSale may display this information publicly or provide it to others. However, PopSale does not disclose personally-identifying information other than as described below.
  
  ### Protection of Certain Personally-Identifying Information
  
  PopSale discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using the Service, you consent to the transfer of such information to them. PopSale may disclose potentially personally-identifying and personally-identifying information publically when You choose to use a feature in the Service that publicizes this informtion (for example, a personal profile). PopSale discloses potentially personally-identifying and personally-identifying information when required to do so by law, or when PopSale believes in good faith that disclosure is reasonably necessary to protect the property or rights of PopSale, third parties or the public at large. If you are a user of the Service and have supplied your email address, phone number, or other contact information, PopSale may contact you via the provided medium. PopSale takes measures to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.
  
  ### Cookies
  A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the Service each time the visitor returns. PopSale may use cookies to help PopSale identify and track visitors, their usage of the Service, and their Service access preferences. PopSale visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using PopSale's websites, with the drawback that certain features of PopSale's websites may not function properly without the aid of cookies.
  
  ### Data Storage
  PopSale uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service. You understand that Your data may be stored on third party storage and transmitted through third party networks.
  
  ### Privacy Policy Changes
  PopSale may change its Privacy Policy from time to time, and in PopSale's sole discretion. PopSale encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change. 
`;

  return (
    <View>
      <Markdown style={ mdStyles }>{copy}</Markdown>
    </View>
  );
}