import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from "expo-image-manipulator";
import * as React from 'react';
import { ActivityIndicator, ImageBackground, ScrollView, Switch, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { SizeProp, validateEmail, shadowStyle, AspectView } from './App';
import Modal from 'modal-enhanced-react-native-web';
import { TOS } from './tos';
import { PP } from './pp';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from './modules/react-router';
import { BrowserRouterProps } from 'react-router-dom';
import { CenteredContent, marginSize } from './App';
import * as qs from 'qs';
var zipcodes = require('zipcodes');
 
const isValidZipCode = (zipCode?: string) => {
  //  TODO:  Check that it's a valid zipcode with "zipcodes" library.
  if (!zipCode) { return false }
  if (zipcodes.lookup(zipCode) == undefined) { return false }
  return true;
}

//  Get ZipCodes within 20 Miles of Granville MA
var rad: string[] = [...zipcodes.radius("01034", 35), ...zipcodes.radius("01301", 35)];
console.log(rad);

interface SubmitForm {
  title?: string;
  description?: string;
  firstName?: string;
  email?: string;
  zipCode?: string;
  isBundle?: boolean;
  imageOne?: any;
  imageTwo?: any;
  imageThree?: any;
  imageFour?: any;
}

export interface IntakeFormProps extends SizeProp {}
interface IntakeFormState {
  submitFormState: SubmitForm;

    //  Submisson
    //  CONSIDER:  This can be made into a COMMON pattern to be applied to these classes.  MAYBE a MIXIN.  Hmm.  ALSO, LOTS of things can be abstracted and made reusable across my codebases!
    pendingSubmission?: boolean;
    submissionModal?: boolean;
    submissionError?: boolean;

    //  Navigation
    tos?: boolean;
    pp?: boolean;

    preRegistered: boolean;
}
class IntakeFormBase extends React.Component<IntakeFormProps, IntakeFormState> {

  constructor(props: IntakeFormProps) {
    super(props);
    this.state = {
      submitFormState: {},
      preRegistered: false
    };
  }

  public componentDidMount = () => {

    //  REFERENCE:  https://stackoverflow.com/posts/37568368/revisions
    const { location } = this.props as any;
    const params: any = qs.parse(location.search, { ignoreQueryPrefix: true });
    //  CONSIDER:  We have a PASSTHROUGH "state mirror" here.. MAYBE instead of being "Imperative" about it, we can DECLARE this as an INTERPRETED patternn and ABSTRACT so we don't have to KEEP implementing it!? HM!  AND similar to plugins and feature injection and stuff? Hm!
    if (params.firstName && params.email) {
      this.setSubmitForm({ firstName: params.firstName, email: params.email })
      this.setState({ preRegistered: true });
    } 
  }

  private isZipCodeWithinServiceArea = () => {
    const zipCode = this.state.submitFormState.zipCode;
    if (!zipCode) { return false; }
    if (rad.indexOf(zipCode) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  

  private validateZipCode = () => {
    const zipCode = this.state.submitFormState.zipCode;
    if (!zipCode) { return false; }
    const isValid = this.isZipCodeWithinServiceArea();
    return isValid;
  }

  private isFormValid = () => {
    const isEmailValid = validateEmail(this.state.submitFormState.email);
    const isZipCodeValid = this.validateZipCode();
    return isEmailValid && isZipCodeValid;
  }

  public setSubmitForm = (object: Partial<SubmitForm>) => {
    this.setState({ submitFormState: { ...this.state.submitFormState, ...object } })
  }

  public pickImage = async (id: string) => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.cancelled) {

      const manipResult = await ImageManipulator.manipulateAsync(result.uri,
        [{ resize: { width: 400 } }],
        { compress: 0.6, format: ImageManipulator.SaveFormat.PNG }
      );

      const changeRecord = { [`image${ id }`]: manipResult.uri }
      this.setSubmitForm(changeRecord as any);
    }
  };

  public submitShareForm = async () => {

    //  Set the Submission
    this.setState({ pendingSubmission: true, submissionModal: true, submissionError: false });

    const res = await fetch(`https://tsogih6iw5.execute-api.us-east-2.amazonaws.com/default/PopSaleEmail`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ type: "submit", ...this.state.submitFormState })
    });

    if ((res.status !== 201) && (res.status !== 200)) {
      this.setState({ pendingSubmission: true, submissionModal: true, submissionError: true });
    }
    const inst = await res.json();
    this.setState({ pendingSubmission: false, submissionModal: true, submissionError: false });
  }

  public render = () => {

    const isDesktop = this.props.sizes.isDesktop;
    const { tos, pp } = this.state;

    return (

      <React.Fragment>

        {/* CONSIDER:  THis COULD be "Injected" into this Class!? HMM  MAYBE we have an API that we use that allows us to have Features that do things like inject Modals and stuff? Hm!  I REALLY think that makes a lot of sense. */}
        <Modal
          animationType="slide"
          // animationDuration={ 200 }
          transparent={false}
          onBackdropPress={ this.state.pendingSubmission ? undefined : () => this.setState({ submissionModal: false }) }
          isVisible={ this.state.submissionModal }
          backdropColor="rgba(0,0,0,0.5)"

          animationIn="zoomInDown"
          animationInTiming={500}
          backdropTransitionInTiming={500}

          animationOut="zoomOutUp"
          animationOutTiming={500}
          backdropTransitionOutTiming={200}
          
        >
        {/* TODO:  CLear the form after submission! MAYBE give the option to do another, OR perhaps to go "home" or something??  MAYBE we'll eventually start our OWN auction site? HM! */}
        <View style={{ backgroundColor: 'white', width: '100%', height: '100%', borderRadius: 20, padding: 40, display: 'flex', flexDirection: 'column' }}>
          <View style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {
              !this.state.pendingSubmission ?
              <TouchableOpacity onPress={ () => this.setState({ submissionModal: false }) } style={{ backgroundColor: '#eeeeee', height: 50, width: 50, borderRadius: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-end' }}>
                <Text style={{ fontFamily: "soleil", fontSize: 22, fontWeight: "900", color: '#aaaaaa', letterSpacing: -.5 }}>X</Text>
              </TouchableOpacity> : 
              null
            }
            <View style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: 350, justifyContent: 'center' }}>
              <Text style={{ fontFamily: "soleil", fontSize: 28, fontWeight: "900", color: '#888888', letterSpacing: -.5, textAlign: 'center' }}>{ this.state.pendingSubmission ? "Submitting" : this.state.submissionError ? 'Failure' : "Complete"  }</Text>

              <View style={{ height: 15 }} />

              {
                this.state.pendingSubmission ?
                  <ActivityIndicator size={ 60 } color="#6a7fbc" /> :
                  this.state.submissionError ? 
                    <Icon name="error" type="material" size={ 60 } color="red" /> :
                    <Icon name="check" type="material" size={ 60 } color="green" />
              }
              
              <View style={{ height: 15 }} />

              <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: '#999999', letterSpacing: -.5, textAlign: 'center' }}>
                {
                  this.state.pendingSubmission ?
                    "Please wait, your request is being submitted.  It may take a minute for your images to upload." :
                    this.state.submissionError ? 
                      "Appologies, but we seem to be having trouble processing your request.  Please reach out to support@popsale.co if this error does not resolve." :
                      // TODO:  Keep engagement going with a linkn to a blog or soemthign??
                      `Thank you ${ this.state.submitFormState.firstName }.  We'll be reaching out to "${ this.state.submitFormState.email }" shortly.`
                }
              </Text>
            </View>
                
          </View>
        </View>
      </Modal>

      <Modal
          animationType="slide"
          // animationDuration={ 200 }
          transparent={false}
          onBackdropPress={ () => this.setState({ tos: false, pp: false }) }
          isVisible={ !!tos }
          backdropColor="rgba(0,0,0,0.5)"

          animationIn="zoomInDown"
          animationInTiming={500}
          backdropTransitionInTiming={500}

          animationOut="zoomOutUp"
          animationOutTiming={500}
          backdropTransitionOutTiming={200}
          
        >
          <View style={{ backgroundColor: 'white', width: '100%', height: '100%', borderRadius: 20, padding: 20 }}>
            <ScrollView showsVerticalScrollIndicator={ false }>
              <TOS />
            </ScrollView>
          </View>
        </Modal>


        <Modal
          animationType="slide"
          // animationDuration={ 200 }
          transparent={false}
          onBackdropPress={ () => this.setState({ tos: false, pp: false }) }
          isVisible={ !!pp }
          backdropColor="rgba(0,0,0,0.5)"
          
          animationIn="zoomInDown"
          animationInTiming={500}
          backdropTransitionInTiming={500}

          animationOut="zoomOutUp"
          animationOutTiming={500}
          backdropTransitionOutTiming={200}
          
        >
          <View style={{ backgroundColor: 'white', width: '100%', height: '100%', borderRadius: 20, padding: 20 }}>
            <ScrollView showsVerticalScrollIndicator={ false }>
              <PP />
            </ScrollView>
          </View>
        </Modal>

{/* Header */}
        {/* IDEA:  Could make the header show rounded corners on the bottom, increased width a bit, with a drop shadow. */}
        <CenteredContent style={{ paddingLeft: isDesktop ? marginSize : 25, paddingRight: isDesktop ? marginSize : 25, zIndex: 10000, overflow: 'visible', backgroundColor: 'white', ...shadowStyle }}>
          
          {/* Header Left Side */}
          <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
            {/* IDEA:  Consider making the logo / button purple? */}
            <ImageBackground source={ require("../assets/popsale_logo.png") } style={{ height: 45, width: 45, tintColor: 'white', marginLeft: 25 } as any} resizeMode="contain" />
            <View style={{ width: 25 }} />
            <Text style={{ fontFamily: "soleil", fontSize: 21, fontWeight: "900", color: '#777777', letterSpacing: -.2 }}>PopSale</Text>
          </View>

        </CenteredContent>

      {/* Share Intro */}
      <View>
        <View style={[{ zIndex: 2, display: 'flex', alignItems: 'center', flexDirection: 'column', backgroundColor: '#2e2549', justifyContent: 'center', padding: 100, marginBottom: isDesktop ? undefined : 20 }, isDesktop ? [] : { ...shadowStyle }]}>
         <Text style={{ fontFamily: "soleil", fontSize: 28, fontWeight: "900", color: '#bfb3e4', letterSpacing: -.5 }}>Request</Text>
         <View style={{ height: 5 }} />
         {
          this.state.preRegistered ?
            <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: 'white', letterSpacing: -.5 }}>Hi { this.state.submitFormState.firstName }!  Tell us what you're selling and we'll send an offer to '{ this.state.submitFormState.email }'.</Text> :
            <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: 'white', letterSpacing: -.5 }}>Tell us what you're selling and we'll send an offer via email.</Text>
         }
              
       </View>

        {/* Share Card */}
        <View style={{ zIndex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', backgroundColor: '#2e2549' }}>
          <View style={[{ zIndex: 1, maxWidth: isDesktop ? 1000 : undefined, flex: isDesktop ? 0 : 1, backgroundColor: 'white', borderRadius: isDesktop ? 30 : undefined, display: 'flex', flexDirection: isDesktop ? 'row' : 'column', overflow: 'hidden', alignSelf: 'auto', marginBottom: isDesktop ? 100 : undefined }, isDesktop ? { shadowColor: "#000", shadowOffset: { width: 0, height: 12 }, shadowOpacity: 0.15, shadowRadius: 30.00 } : null]}>

              {/* Left Section Content */}
              <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 1, margin: 45 }}>

                {/* Sale Info Title */}
                <View style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                  <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 22, fontWeight: "800", color: '#999999', letterSpacing: -.5 }}>Sale Info</Text>
                </View>

                {/* Request Info */}
                <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 16, fontWeight: "800", color: '#9784d1', letterSpacing: -.5 }}>Title</Text>
                <View style={{ height: 5 }} />
                <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 14, fontWeight: "700", color: '#999999', letterSpacing: -.5 }}>Make a title for your item(s);</Text>
                <TextInput style={{ fontFamily: "soleil", fontWeight: "800", marginTop: 3, marginBottom: 3, backgroundColor: 'white', color: "#7e7e7e", fontSize: 14, height: 30, alignSelf: 'stretch', borderColor: '#DCDCDC', borderBottomWidth: 2 }} onChangeText={ (text) => this.setSubmitForm({ title: text }) } />

                <View style={{ height: 20 }} />

                <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 16, fontWeight: "800", color: '#9784d1', letterSpacing: -.5 }}>Description</Text>
                <View style={{ height: 5 }} />
                <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 14, fontWeight: "700", color: '#999999', letterSpacing: -.5 }}>Describe your item(s) in detail.</Text>
                <TextInput onChangeText={ (text) => this.setSubmitForm({ description: text }) } multiline={ true } style={{ paddingTop: 5, paddingBottom: 5, marginTop: 3, marginBottom: 3, backgroundColor: 'white', color: "#9e9e9e", fontFamily: 'soleil', fontWeight: "800", fontSize: 14, borderColor: '#DCDCDC', borderBottomWidth: 2, height: 150, alignSelf: 'stretch' }} />

                <View style={{ height: 20 }} />

                <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 16, fontWeight: "800", color: '#9784d1', letterSpacing: -.5 }}>Multiple Items</Text>
                <View style={{ height: 5 }} />
                <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 14, fontWeight: "700", color: '#999999', letterSpacing: -.5 }}>Are you selling a bundle of items?</Text>
                <View style={{ height: 5 }} />
                <Switch value={ this.state.submitFormState.isBundle } onValueChange={ () => this.setSubmitForm({ isBundle: !this.state.submitFormState.isBundle }) } trackColor={ '#EEEEEE' as any }  { ...{ activeTrackColor: '#9784d1', activeThumbColor: '#EEEEEE' }} thumbColor='#EEEEEE' style={{ height: 30 }} />

                <View style={{ height: 30 }} />

                {/* Contact Info Title */}
                <View style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                  <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 22, fontWeight: "800", color: '#999999', letterSpacing: -.5 }}>Contact Info</Text>
                </View>

                {
                  !this.state.preRegistered ?
                  <React.Fragment>
                    <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 16, fontWeight: "800", color: '#9784d1', letterSpacing: -.5 }}>First Name</Text>
                    <View style={{ height: 5 }} />
                    <TextInput onChangeText={ (text) => this.setSubmitForm({ firstName: text }) } style={{ fontFamily: "soleil", fontWeight: "800", marginTop: 3, marginBottom: 3, backgroundColor: 'white', color: "#7e7e7e", fontSize: 14, height: 30, alignSelf: 'stretch', borderColor: '#DCDCDC', borderBottomWidth: 2 }} />
                    <View style={{ height: 20 }} />

                    <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 16, fontWeight: "800", color: '#9784d1', letterSpacing: -.5 }}>Email</Text>
                    <View style={{ height: 5 }} />
                    <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 14, fontWeight: "700", color: '#999999', letterSpacing: -.5 }}>We'll send an offer to this email</Text>
                    <TextInput onChangeText={ (text) => this.setSubmitForm({ email: text }) } style={{ fontFamily: "soleil", fontWeight: "800", marginTop: 3, marginBottom: 3, backgroundColor: 'white', color: "#7e7e7e", fontSize: 14, height: 30, alignSelf: 'stretch', borderColor: '#DCDCDC', borderBottomWidth: 2 }} />
                    <View style={{ height: 20 }} />
                  </React.Fragment> : 
                  null
                }

                <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 16, fontWeight: "800", color: '#9784d1', letterSpacing: -.5 }}>Zip Code</Text>
                <View style={{ height: 5 }} />
                <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 14, fontWeight: "700", color: '#999999', letterSpacing: -.5 }}>Zip code of the area you prefer to meet</Text>
                <TextInput onChangeText={ (text) => this.setSubmitForm({ zipCode: text }) } style={{ fontFamily: "soleil", fontWeight: "800", marginTop: 3, marginBottom: 3, backgroundColor: 'white', color: "#7e7e7e", fontSize: 14, height: 30, alignSelf: 'stretch', borderColor: '#DCDCDC', borderBottomWidth: 2 }} />
                {/* TODO:  Support a request form for a larger service area!? HM!  Be NICE about it and THANK them.  ALSO, consider letting them commit to a location before accepting the offer? HM! */}
                {
                  this.state.submitFormState.zipCode && this.state.submitFormState.zipCode.length === 5 && !isValidZipCode(this.state.submitFormState.zipCode) ?
                    <View style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                      <Icon name="info" type="material" color="#999999" />
                      <View style={{ width: 5 }} />
                      <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 14, fontWeight: "700", color: '#999999', letterSpacing: -.5 }}>Please enter a valid ZipCode.</Text>
                    </View> :
                    null
                }

                {
                  isValidZipCode(this.state.submitFormState.zipCode) && !this.isZipCodeWithinServiceArea() ?
                    <View style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                      <Icon name="info" type="material" color="#999999" />
                      <View style={{ width: 5 }} />
                      <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 14, fontWeight: "700", color: '#999999', letterSpacing: -.5 }}>Unfortunaely, we do not currently service this area.</Text>
                    </View> :
                    null
                }

{
                  isValidZipCode(this.state.submitFormState.zipCode) && this.isZipCodeWithinServiceArea() ?
                    <View style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                      <Icon name="check" type="material" color="green" />
                      <View style={{ width: 5 }} />
                      <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 14, fontWeight: "700", color: '#999999', letterSpacing: -.5 }}>Good news, we service your area!</Text>
                    </View> :
                    null
                }

              </View>


            {/* Right Section Content */}
            <View style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'flex-start', margin: 45 }}>

              {/* Photo Section */}
              <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 18, fontWeight: "800", color: '#9784d1', letterSpacing: -.5 }}>Photos</Text>
              <View style={{ height: 5 }} />
              <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 14, fontWeight: "700", color: '#999999', letterSpacing: -.5 }}>Upload photos of your item(s).</Text>
              <View style={{ height: 30 }} />

              <View style={{ display: 'flex', flexDirection: 'row', flex: 1, alignSelf: 'stretch' }}>
                <AspectView style={{ flex: 1, borderRadius: 10, backgroundColor: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', overflow: 'visible' }}>
                  <TouchableOpacity onPress={ () => this.pickImage("One") } style={{ flex: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    { this.state.submitFormState.imageOne  ? 
                      <ImageBackground source={{ uri: this.state.submitFormState.imageOne }} style={{ flex: 1 }} resizeMode='cover' /> :
                      <Icon name="photo" type="material" size={ 50 } color="#D5D5D5" />
                    }
                  </TouchableOpacity>
                </AspectView>
                <View style={{ width: 20 }} />
                <AspectView style={{ flex: 1, backgroundColor: 'white', borderRadius: 10, display: 'flex', flexDirection: 'column', overflow: 'visible' }}>
                  <TouchableOpacity onPress={ () => this.pickImage("Two") } style={{ flex: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    { this.state.submitFormState.imageTwo  ? 
                      <ImageBackground source={{ uri: this.state.submitFormState.imageTwo }} style={{ flex: 1 }} resizeMode='cover' /> :
                      <Icon name="photo" type="material" size={ 50 } color="#D5D5D5" />
                    }
                  </TouchableOpacity>
                </AspectView>
              </View>
              
              <View style={{ height: 30 }} />
           
              <Text style={{ textAlign: 'left' ,fontFamily: "soleil", fontSize: 14, fontWeight: "700", color: '#999999', letterSpacing: -.5 }}>
                By clicking Submit, you agree to our <Text style={{ color: "#6a7fbc" }} onPress={() => this.setState({ tos: true, pp: false })}>Terms</Text>, and <Text style={{ color: "#6a7fbc" }} onPress={() => this.setState({ pp: true, tos: false })}>Privacy Policy</Text>.  You may receive email Communication from us and can opt out any time.
              </Text>

              <View style={{ height: 10 }} />

              <TouchableOpacity disabled={ !this.isFormValid() } onPress={ () => { this.isFormValid() ? this.submitShareForm() : null } } style={{ backgroundColor: this.isFormValid() ? '#6a7fbc' : '#aaaaaa', borderRadius: 20, shadowColor: "#000", shadowOffset: { width: 0, height: 12 }, shadowOpacity: 0.15, shadowRadius: 30.00, display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 200, height: 40, overflow: 'visible', alignItems: 'center', alignSelf: 'flex-end' }}>
                <Text style={{ textAlign: 'left' ,fontFamily: "soleil", fontSize: 16, fontWeight: "800", color: 'white', letterSpacing: -.5 }}>Submit</Text>
              </TouchableOpacity>
              
            </View>
          </View>

        {/* Gray Bottom Underlap */}
        {
          isDesktop ?
            <View style={{ zIndex: 0, display: 'flex', position: 'absolute', width: '100%', height: 400, bottom: 0, backgroundColor: '#EEEEEE' }} /> :
            null
        }

      </View>
    </View>

    {/* Footer */}
    <View style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', backgroundColor: '#444444', justifyContent: 'center', padding: 30 }}>
      <Text style={{ fontFamily: "soleil", fontSize: 14, fontWeight: "900", color: '#bfb3e4', letterSpacing: -.5 }}>PopSale</Text>
      <View style={{ height: 5 }} />
      <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 12, fontWeight: "600", color: 'white', letterSpacing: -.5 }}>Copyright 2020</Text>
    </View>
    </React.Fragment>
  );
  }
}

const withSizes = (Component: any) => {
  return () => {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const isTablet = useMediaQuery({ minWidth: 500, maxWidth: 767 });
    const isMobile = useMediaQuery({ maxWidth: 499 });
    const sizes = {
      isDesktop,
      isTablet,
      isMobile
    }
    return <Component sizes={ sizes } />;
  }
}

export const IntakeForm = withSizes(withRouter(IntakeFormBase as any));
