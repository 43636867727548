import fetch from 'cross-fetch';
import Modal from 'modal-enhanced-react-native-web';
import * as React from 'react';
import { ActivityIndicator, ImageBackground, LayoutAnimation, ScrollView, StyleSheet, Switch, Text, TextInput, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Icon } from 'react-native-elements';
import { useMediaQuery } from 'react-responsive';
import { IntakeForm } from './Intake';
import { Route, Router } from "./modules/react-router";
var zipcodes = require('zipcodes');

export const shadowStyle = { shadowColor: "#000", shadowOffset: { width: -3, height: 3 }, shadowOpacity: 0.05, shadowRadius: 6.00 };

// class Root {

// }

// const ModalAPI = {
//   showModal: () => {

//   }
// }

//  NOTE:  This is a GREAT example of making a SYMBOL that has meaning inn a CONTEXT... hmm
export const marginSize = 0;

//  NOTE:  IF we want to support CONDITIONAL types, FOR NOW, it can make sense to break it out into different functions with different interfaces.. hmm..


interface SignUpForm {
  name?: string;
  email?: string;
  joinMailing?: boolean;
}

export const InfoIcon = ({ title, icon, onPress }: { title: string, icon: { name: string, type: string }, onPress: any}) => (
  <AspectView style={{ aspectRatio: 1, flex: 1 }}>
    <TouchableOpacity style={{ flex: 1, backgroundColor: '#f8f8f8', borderRadius: marginSize, alignItems: 'center', justifyContent: 'center', padding: 30 }} onPress={ onPress }>
      <Icon name={ icon.name } type={ icon.type } color="#999999" size={ 35 } />
      <View style={{ height: 10 }} />
      <Text style={{ fontFamily: "soleil", fontSize: 18, fontWeight: "900", color: '#999999', letterSpacing: -.5 }}>{ title }</Text>
    </TouchableOpacity>  
  </AspectView>
);

//  REFERENCE:  https://ui.dev/validate-email-address-javascript/
export function validateEmail (email?: string) {
  return email ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) : false;
}

const Desktop = ({ children }: { children: any }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 })
  return isDesktop ? children : null
}
const Tablet = ({ children }: { children: any }) => {
  const isMobile = useMediaQuery({ minWidth: 500, maxWidth: 767 })
  return isMobile ? children : null
}
const Mobile = ({ children }: { children: any }) => {
  const isMobile = useMediaQuery({ maxWidth: 499 })
  return isMobile ? children : null
}
const Default = ({ children }: { children: any }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

//  REFERENCE:  https://baconbrix.gitbook.io/react-native-web/styling/aspect-ratio
export function AspectView(props: any) {
  const [layout, setLayout] = React.useState(null) as any;

  const { aspectRatio = 1, ...inputStyle } =
    StyleSheet.flatten(props.style) || {};
  const style = [inputStyle, { aspectRatio }];

  if (layout) {
    const { width = 0, height = 0 } = layout;
    if (width === 0) {
      style.push({ width: height * aspectRatio, height });
    } else {
      style.push({ width, height: width * aspectRatio });
    }
  }

  return (
    <View
      {...props}
      style={style}
      onLayout={({ nativeEvent: { layout } }) => { if (props.style.aspectRatio !== 'auto') { setLayout(layout) }}}
    />
  );
}

export const mdStyles = StyleSheet.create({
  heading: {
    fontSize: 34,
    color: 'black',
    fontFamily: 'soleil',
    fontWeight: "700"
  },
  heading1: {
    fontSize: 32,
    color: 'black',
    fontFamily: 'soleil',
    fontWeight: "700"
  },
  heading2: {
    fontSize: 24,
    color: 'black',
    fontFamily: 'soleil',
    fontWeight: "700"
  },
  heading3: {
    fontSize: 18,
    color: 'black',
    fontFamily: 'soleil',
    fontWeight: "600"
  },
  heading4: {
    fontSize: 16,
    color: 'black',
    fontFamily: 'soleil',
    fontWeight: "600"
  },
  heading5: {
    fontSize: 13,
    color: 'black',
    fontFamily: 'soleil',
    fontWeight: "600"
  },
  heading6: {
    fontSize: 11,
    color: 'black',
    fontFamily: 'soleil',
    fontWeight: "600"
  }
});


const Overlay2 = ({ style }: { style: ViewStyle }) => {
  return <View style={{ position: 'absolute', width: '150%', height: '150%', backgroundColor: 'black', opacity: 0.3, ...style }} />;
}

export const CenteredContent = ({ children, style }: { children: any, style?: ViewStyle }) => {
  return (
    // Outer Container:  We need to use a row so we can BOTH center AND stretch with a MAX width.
    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', zIndex: 1000 }}>
      <View style={{ paddingLeft: 80, paddingRight: 80, maxWidth: 1760, height: 80, display: 'flex', flexDirection: 'row', flex: 1, ...style }}>
        { children }
      </View>
    </View>
  );
}
interface AppProps extends SizeProp {
}

interface AppState {

  signUpFormState: SignUpForm;

  //  UI State
  signUpPosition?: number;
  aboutPosition?: number;

  //  Submisson
  pendingSubmission?: boolean;
  submissionModal?: boolean;
  submissionError?: boolean;

  // moreInfoSelection?: string;
  featureButtonSelection?: string;

  markedHelpful?: boolean;
}

export class AppComponentBase extends React.Component<AppProps, AppState> {

  constructor(props: any) {
    super(props);
    this.state = {
      signUpFormState: {
        joinMailing: true
      }
    };
  }

  public setSignUpForm = (object: Partial<SignUpForm>) => {
    this.setState({ signUpFormState: { ...this.state.signUpFormState, ...object } })
  }

  public componentWillReceiveProps = async () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);
  }

  //  TODO:  Add DETAILS as to WHY a validation is failing, especially for ZipCode.

  private isSignUpFormValid = () => {
    const isEmailValid = validateEmail(this.state.signUpFormState.email);
    return isEmailValid && this.state.signUpFormState.joinMailing !== undefined && this.state.signUpFormState.name !== undefined;
  }

  public featureButtonClicked = async (featureButtonSelection: string) => {

    // console.log("Feature Button Clicked");
    // this.setState({ featureButtonSelection });

    // console.log("About to submit more info data");
    // const res = await fetch(`https://tsogih6iw5.execute-api.us-east-2.amazonaws.com/default/PopSaleEmail`, {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ type: "featureButton", selection: featureButtonSelection })
    // });

    // if ((res.status !== 201) && (res.status !== 200)) {
    //   console.log("Error Sending More Info Data");
    // } else {
    //   console.log("Sent More Info Data");
    // }
  }

  public submitSignUpForm = async () => {

    //  Set the Submission
    this.setState({ pendingSubmission: true, submissionModal: true, submissionError: false });

    const res = await fetch(`https://tsogih6iw5.execute-api.us-east-2.amazonaws.com/default/PopSaleEmail`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ type: "signUp", ...this.state.signUpFormState })
    });

    if ((res.status !== 201) && (res.status !== 200)) {
      this.setState({ pendingSubmission: true, submissionModal: true, submissionError: true });
    }
    const inst = await res.json();
    this.setState({ pendingSubmission: false, submissionModal: true, submissionError: false });
  }



  // public moreInfoClicked = async (moreInfoSelection: string) => {

  //   //  Set the Submission
  //   // this.setState({ pendingSubmission: true, submissionModal: true, submissionError: false });

  //   console.log("More Info Clicked");
  //   this.setState({ moreInfoSelection });

  //   console.log("About to submit more info data");
  //   const res = await fetch(`https://tsogih6iw5.execute-api.us-east-2.amazonaws.com/default/PopSaleEmail`, {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({ type: "moreInfo", moreInfo: moreInfoSelection })
  //   });

  //   if ((res.status !== 201) && (res.status !== 200)) {
  //     console.log("Error Sending More Info Data");
  //   } else {
  //     console.log("Sent More Info Data");
  //   }
  // }


  public bookmark = () => {
    

  }

  //  TODO:  Should be exterrnalized in that widget, WITH support for pluggabliity and events and stuff!?? HMMmmm.. maybe we can PLUG in code to ENBLE a higherr level events system!? Hm!!
  public markHelpful = (helpful: boolean) => {
    //  TODO:  Send an email to mark as marked helpful!
    this.setState({ markedHelpful: helpful });
  }

  private FeatureInfo = () => {
    return (
      <React.Fragment>
        <View style={{ minHeight: marginSize + 1, height: marginSize + 1, flex: 0, flexShrink: 0 }} />

        <View style={{ flex: 1, display: 'flex', flexDirection: 'row', alignSelf: 'stretch' }}>


          <InfoIcon title="Fast" icon={{ name: "run", type: "material-community" }} onPress={() => { this.featureButtonClicked("Fast") }} />
          <View style={{ width: marginSize + 1 }} />
          <InfoIcon title="Safe" icon={{ name: "lifebuoy", type: "entypo" }} onPress={ () => { this.featureButtonClicked("Safe") } } />
          

        </View>

        <View style={{ minHeight: marginSize + 1, height: marginSize + 1, flex: 0, flexShrink: 0 }} />

        <View style={{ flex: 1, display: 'flex', flexDirection: 'row', alignSelf: 'stretch' }}>

          <InfoIcon title="Simple" icon={{ name: "bubble-chart", type: "material" }} onPress={ () => { this.featureButtonClicked("Simple") } } />
          
          {/* TODO:  Talk about Dangerr of selling online AND covid.  MAYBE make this a SEPARATE point? MAYBE also talk about how we're CLEAN? Hm */}
          <View style={{ width: marginSize + 1 }} />
          <InfoIcon title="Local" icon={{ name: "map", type: "material-community" }} onPress={ () => { this.featureButtonClicked("Local") } } />
        </View>

        {/* TODO:  Fix vertical margins. */}
        <View style={{ minHeight: marginSize + 1, height: marginSize + 1, flex: 0, flexShrink: 0 }} />

        <View style={{ flex: 1, display: 'flex', flexDirection: 'row', alignSelf: 'stretch' }}>

          <InfoIcon title="Reliable" icon={{ name: "sync", type: "material" }} onPress={ () => { this.featureButtonClicked("Reliable") } } />
          <View style={{ width: marginSize + 1 }} />
          <InfoIcon title="Rewarding" icon={{ name: "trophy", type: "font-awesome" }} onPress={ () => { this.featureButtonClicked("Rewarding") } } />

        </View>

        <View style={{ minHeight: marginSize + 1, height: marginSize + 1, flex: 0, flexShrink: 0 }} />
      </React.Fragment>
    );
  }
  public App = () => {

  const { isDesktop, isTablet, isMobile } = this.props.sizes;

    //  CONSIDER:  Implement Router or React Navigation

    //  NOTE:  maxWidth of the content container an the paddings are taken from AirBnb.

    const FeatureInfo = this.FeatureInfo;

    return (
      <View style={{ flex: 1, display: 'flex', flexDirection: 'column', backgroundColor: '#dddddd' }}>

        <Modal
          animationType="slide"
          // animationDuration={ 200 }
          transparent={false}
          onBackdropPress={ () => this.setState({ featureButtonSelection: undefined, markedHelpful: undefined }) }
          isVisible={ !!this.state.featureButtonSelection }
          // backdropColor="rgba(0,0,0,0.5)"
          backdropStyle={{ backgroundColor: 'red' }}

          animationIn="zoomInDown"
          animationInTiming={500}
          backdropTransitionInTiming={500}

          animationOut="zoomOutUp"
          animationOutTiming={500}
          backdropTransitionOutTiming={200}
          
        >
          <View style={{ backgroundColor: 'white', maxWidth: 700, height: '100%', borderRadius: marginSize, padding: 50, alignSelf: 'center' }}>
            <ScrollView showsVerticalScrollIndicator={ false }>
              
              <View style={{ height: marginSize }} />
              {
                this.state.featureButtonSelection === "Fast"  ?
                <View style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
                  <Text style={{ fontFamily: "soleil", fontSize: 30, fontWeight: "800", color: '#888888', letterSpacing: -.5 }}>Declutter</Text>
                  <View style={{ maxWidth: 500, flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <View style={{ height: 30 }} />

                    <View style={{ width: 200, height: 200, borderRadius: 50, backgroundColor: '#f6edff', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <Icon name="broom" type="material-community" color="#888888" size={ 45 } />
                    </View>

                    <View style={{ height: 30 }} />

                    <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: '#aaaaaa', letterSpacing: -.5 }}>Researchers at the University of California have shown that a cluttered home leads to more stress [1].  In fact, the researchers conclude that:</Text>
                    
                    <View style={{ height: 30 }} />

                    {/* Quote Box */}
                    {/* IDEA: Ah!!  MAYBE I can take all these components and make a React Natative GENERIC componetns that we can use in all our apps WHILE keeping things pluggable and dynamic! HM!  Modulrr and all that!  PROGRESSIVE ABSTRACTION!!!  Similar to PROGRESSIVE EXPOSURRE AND DISCLOSURE!!! */}
                    <View style={{ flexDirection: 'row', display: 'flex', borderLeftColor: '#aaaaaa', borderLeftWidth: 3, marginHorizontal: 30, paddingLeft: 10 }}>
                      <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "800", color: '#aaaaaa', letterSpacing: -.5 }}>...individual's subjective descriptions of their homes were linked with their subsequent patterns of mood and cortisol. [1]</Text>
                    </View>

                    <View style={{ height: 30 }} />

                    <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: '#aaaaaa', letterSpacing: -.5 }}>That is to say, de-cluttering your home may do more than get you some extra money.  You may actually improve your well-being.</Text>

                    <View style={{ height: 15 }} />
                    <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: '#aaaaaa', letterSpacing: -.5 }}>Moreover, selling online can be stressful in its own right.  According to the Federal Trade Commission, 10% of adults will be a victim to fraud, the majority of which occur online [2].  Countless scientific research papers have been written in an attempt to understand these online scammers [3, 4, 5].</Text>
                    <View style={{ height: 15 }} />
                    <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: '#aaaaaa', letterSpacing: -.5 }}>Instead of trusting random strangers, work with our family-owned team.  Give us the chance to earn your trust, and you can avoid the complexities that come with selling online.</Text>

                    <View style={{ height: 30 }} />
                    <View>
                      <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: '#aaaaaa', letterSpacing: -.5 }}>References</Text>
                      <Text style={{ fontFamily: "soleil", fontSize: 12, fontWeight: "600", color: '#aaaaaa', letterSpacing: -.5 }}>1:  http://repettilab.psych.ucla.edu/no%20place%20like%20home.pdf</Text>
                      <Text style={{ fontFamily: "soleil", fontSize: 12, fontWeight: "600", color: '#aaaaaa', letterSpacing: -.5 }}>2:  https://legaljobsite.net/scam-statistics/</Text>
                      <Text style={{ fontFamily: "soleil", fontSize: 12, fontWeight: "600", color: '#aaaaaa', letterSpacing: -.5 }}>3:  https://ieeexplore.ieee.org/abstract/document/6565240</Text>
                      <Text style={{ fontFamily: "soleil", fontSize: 12, fontWeight: "600", color: '#aaaaaa', letterSpacing: -.5 }}>4:  https://link.springer.com/chapter/10.1007/978-3-662-54970-4_1</Text>
                      <Text style={{ fontFamily: "soleil", fontSize: 12, fontWeight: "600", color: '#aaaaaa', letterSpacing: -.5 }}>5:  https://ieeexplore.ieee.org/abstract/document/6963162/</Text>
                    </View>
                  </View>

                  <View style={{ height: 30 }} />

                  {/* Helpful Survey Widget */}
                  {/* TODO:  THIS is an example of an INJECTABLE thinng that we can just use with an externnal service to collect this data and shit?  BUT I DON'T want new accounts to hold data for ALL these fucking widgets!  SO, AH!!!  MAYBE this is where we offer FULLY CONNENCTED widgets for shit, BUT where we enable them JUST with an API key!? HM!!!! */}                    
                  {/* REALIZATION:  THESE are EXACTLY the types of thigs I'd like to GENERALIZE and shit.  I want these to be available everywhere?? Hm */}
                  {/* TODO:  LEAD INTO a message box to ask WHY it was NOT helpful.  MAYBE lead in with the helpful box, OR perhaps just reward them? Hm!  MAYBE give them a coupon code? HM! */}
                  <View style={{ display: 'flex', flexDirection: 'column', overflow: 'visible', padding: 20 }}>
                    <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: '#aaaaaa', letterSpacing: -.5 }}>Did you find this information helpful?</Text>

                    <View style={{ display: 'flex', flexDirection: 'row', overflow: 'visible' }}>
                      <TouchableOpacity onPress={ () => this.markHelpful(true) } style={{ display: 'flex', flexDirection: 'row', width: 115, height: 38, borderRadius: 10, backgroundColor: this.state.markedHelpful === true ? '#e9f3fe' : 'white', alignItems: 'center', justifyContent: 'center', ...shadowStyle }}>
                        <Icon name="thumbs-up" type="feather" color={ this.state.markedHelpful ? '#3378ea' : "#aaaaaa" } size={ 15 } />
                        <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "700", letterSpacing: -.2, top: -1, position: 'relative', color: this.state.markedHelpful ? '#3378ea' : "#aaaaaa" }}>Yes</Text>
                      </TouchableOpacity>

                      <View style={{ width: 15 }} />

                      <TouchableOpacity onPress={ () => this.markHelpful(false) } style={{ display: 'flex', flexDirection: 'row', width: 115, height: 38, borderRadius: 10, backgroundColor: this.state.markedHelpful === false ? '#f55b47' : 'white', alignItems: 'center', justifyContent: 'center', ...shadowStyle }}>
                        <Icon name="thumbs-down" type="feather" color={ this.state.markedHelpful === false ? '#d4240d' : '#aaaaaa' } size={ 15 } />
                        <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "700", letterSpacing: -.2, top: -1, position: 'relative', color: this.state.markedHelpful === false ? '#d4240d' : '#aaaaaa' }}>No</Text>
                      </TouchableOpacity>
                    </View>
                  </View>

                </View> :
                <Text>Nothing Selected</Text>
              }
            </ScrollView>
          </View>
        </Modal>

      <Modal
        animationType="slide"
        // animationDuration={ 200 }
        transparent={false}
        onBackdropPress={ this.state.pendingSubmission ? undefined : () => this.setState({ submissionModal: false }) }
        isVisible={ this.state.submissionModal }
        backdropColor="rgba(0,0,0,0.5)"

        animationIn="zoomInDown"
        animationInTiming={500}
        backdropTransitionInTiming={500}

        animationOut="zoomOutUp"
        animationOutTiming={500}
        backdropTransitionOutTiming={200}
        
      >
        {/* TODO:  CLear the form after submission! MAYBE give the option to do another, OR perhaps to go "home" or something??  MAYBE we'll eventually start our OWN auction site? HM! */}
        <View style={{ backgroundColor: 'white', width: '100%', height: '100%', borderRadius: 20, padding: 40, display: 'flex', flexDirection: 'column' }}>
          <View style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {
              !this.state.pendingSubmission ?
              <TouchableOpacity onPress={ () => this.setState({ submissionModal: false }) } style={{ backgroundColor: '#eeeeee', height: 50, width: 50, borderRadius: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignSelf: 'flex-end' }}>
                <Text style={{ fontFamily: "soleil", fontSize: 22, fontWeight: "900", color: '#aaaaaa', letterSpacing: -.5 }}>X</Text>
              </TouchableOpacity> : 
              null
            }
            <View style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: 350, justifyContent: 'center' }}>
              <Text style={{ fontFamily: "soleil", fontSize: 28, fontWeight: "900", color: '#888888', letterSpacing: -.5, textAlign: 'center' }}>{ this.state.pendingSubmission ? "Submitting" : this.state.submissionError ? 'Failure' : "Complete"  }</Text>

              <View style={{ height: 15 }} />

              {
                this.state.pendingSubmission ?
                  <ActivityIndicator size={ 60 } color="#6a7fbc" /> :
                  this.state.submissionError ? 
                    <Icon name="error" type="material" size={ 60 } color="red" /> :
                    <Icon name="check" type="material" size={ 60 } color="green" />
              }
              
              <View style={{ height: 15 }} />

              <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: '#999999', letterSpacing: -.5, textAlign: 'center' }}>
                {
                  this.state.pendingSubmission ?
                    "Please wait, your request is being submitted.  It may take a minute for your images to upload." :
                    this.state.submissionError ? 
                      "Appologies, but we seem to be having trouble processing your request.  Please reach out to support@popsale.co if this error does not resolve." :
                      // TODO:  Keep engagement going with a linkn to a blog or soemthign??
                      `Thank you ${ this.state.signUpFormState.name }.  We'll be reaching out to "${ this.state.signUpFormState.email }" shortly.`
                }
              </Text>
            </View>
                
          </View>
        </View>
      </Modal>

        {/* Header */}
        {/* IDEA:  Could make the header show rounded corners on the bottom, increased width a bit, with a drop shadow. */}
        <CenteredContent style={{ paddingLeft: isDesktop ? marginSize : 25, paddingRight: isDesktop ? marginSize : 25, zIndex: 10000, overflow: 'visible', backgroundColor: 'white', ...shadowStyle }}>
          
          {/* Header Left Side */}
          <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
            {/* IDEA:  Consider making the logo / button purple? */}
            <ImageBackground source={ require("../assets/popsale_logo.png") } style={{ height: 45, width: 45, tintColor: 'white', marginLeft: 25 } as any} resizeMode="contain" />
            <View style={{ width: 25 }} />
            <Text style={{ fontFamily: "soleil", fontSize: 21, fontWeight: "900", color: '#777777', letterSpacing: -.2 }}>PopSale</Text>
          </View>

          {/* Header Right Side */}
          {/* TODO:  Use a cooler looking menu */}
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {/* <Desktop> */}
              <Text onPress={ () => scrollTo({ top: this.state.aboutPosition }) } style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "700", color: '#777777', letterSpacing: -.2 }}>About</Text>
              <View style={{ width: 30 }} />
              <TouchableOpacity onPress={ () => scrollTo({ top: this.state.signUpPosition }) } style={{ width: 115, height: 38, borderRadius: 20, backgroundColor: '#6a7fbc', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "700", color: 'white', letterSpacing: -.2, top: -1, position: 'relative' }}>Sign Up</Text>
              </TouchableOpacity>
              <View style={{ width: 25 }} />


            {/* </Desktop> */}
            
            {/* {
              !isDesktop ?
                <Icon type="material" name="menu" style={{ fontSize: 18, fontWeight: "700", color: 'white' }} color="#777777" size={ 27 } /> :
                null
            } */}
            
          </View>

        </CenteredContent>

        {/* Hero Region */}
        <AspectView style={{ aspectRatio: isDesktop ? 0.45 : .75, maxHeight: 750, overflow: 'visible', zIndex: 1 }}>

          <View style={{ overflow: 'visible', zIndex: 5000, position:'absolute', width: '100%', height: '100%', display: 'flex', flexDirection: 'row', paddingTop: 0 }}>

            <View style={{ width: marginSize }} />

            <View style={{ justifyContent: 'center', flex: isDesktop ? 2/3 : 1, flexDirection: 'row', zIndex: 0, borderRadius: marginSize, overflow: 'hidden', marginTop: marginSize, ...shadowStyle }}>

              {/* Background Image */}
              <ImageBackground source={ require('../assets/happy.jpg') } resizeMode='cover' style={{ height: '100%', width: '100%', position: 'absolute', zIndex: 1, overflow: 'hidden' }} imageStyle={{ width: '120%', height: '120%', left: 0 }} />
              
              {/* Overlay */}
              <View style={{ zIndex: 2, position: 'absolute', width: '100%', height: '100%', backgroundColor: 'black', opacity: isDesktop ? 0.25 : 0.4 }} />

              {/* Main Layout Widget */}
              <View style={{ zIndex: 3, maxWidth: 1760, paddingLeft: isDesktop ? 80 : 25, paddingRight: isDesktop ? 80 : 25, flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                {/* Hero Content */}
                <View style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: isDesktop ? 'flex-start' : 'center', justifyContent: 'center' }}>

                  {/* Center Title */}
                  <Text style={{ fontFamily: "Times New Roman", fontSize: isMobile ? 35 : 50, fontWeight: "900", color: 'white', letterSpacing: -.5 }}>Sell Used Stuff</Text>
                  <Text style={{ fontFamily: "Times New Roman", fontSize: isMobile ? 35 : 50, fontWeight: "900", color: 'white', letterSpacing: -.5, marginTop: -8 }}>Quickly and Safely</Text>

                  <View style={{ height: isDesktop ? 20 : 0 }} />

                  <View style={{ maxWidth: 500, padding: isDesktop ? undefined : 30 }}>
                    <Text style={{ textAlign: isDesktop ? 'left' : 'center', fontFamily: "soleil", fontSize: isMobile ? 20 : 22, fontWeight: "700", color: 'white', letterSpacing: -.5 }}>We make it easy to sell used stuff online. { isDesktop ? "\n" : null }Owned and operated in Western, MA.</Text>
                  </View>

                  <View style={{ height: isDesktop ? 25 : 0 }} />

                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <TouchableOpacity onPress={ () => scrollTo({ top: this.state.signUpPosition }) } style={{ width: 115, height: 38, borderRadius: 20, backgroundColor: '#6a7fbc', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "700", color: 'white', letterSpacing: -.2, top: -1, position: 'relative' }}>Sign Up</Text>
                    </TouchableOpacity>

                    <View style={{ width: marginSize }} />

                    {/* NOTE:  Nice red color - eb527d */}
                    {/* <TouchableOpacity onPress={ () => this.bookmark() } style={{ width: 115, height: 38, borderRadius: 20, backgroundColor: '#eeeeee', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "700", color: '#777777', letterSpacing: -.2, top: -1, position: 'relative' }}>Learn More</Text>
                    </TouchableOpacity> */}
                  </View>
                  
                </View>

              </View>

            </View>

            

            {/* <View style={{ margin: 70, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Text style={{ fontFamily: "soleil", fontSize: 22, fontWeight: "800", color: '#999999', letterSpacing: -.5 }}>What is PopSale?</Text>
              <View style={{ height: 5 }} />
              <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: '#cccccc', letterSpacing: -.5 }}>We make it easy for you to sell used goods online.  Click below to learn more.</Text>
              <View style={{ height: 5 }} />
            </View> */}

            {/* to We're a small business based in Western, MA */}

            {
              isDesktop ?
                <View style={{ flex: 1/3, overflow: 'visible', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <View style={{ flex: 1, display: 'flex', flexDirection: 'column', alignSelf: 'stretch', overflow: 'hidden' }}>
                    <FeatureInfo />
                  </View>
                </View> :
                null
            }

              {/* <View style={{ width: '80%' }}>
                <Text style={{ fontFamily: "soleil", fontSize: 22, fontWeight: "800", color: 'white', letterSpacing: -.5 }}>Sign Up</Text>
                <View style={{ height: 5 }} />
                <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: '#eeeeee', letterSpacing: -.5 }}>When you sign-up we'll send you an email explaining how to sell with us.</Text>
                <View style={{ height: 5 }} />
              </View> */}

            


            {/* <View style={{ width: 30 }} /> */}
            
          </View>

        </AspectView>

        {
          !isDesktop ?
            <View style={{ flex: 1, display: 'flex', flexDirection: 'column', alignSelf: 'stretch', overflow: 'hidden' }}>
              <FeatureInfo />
            </View> :
            null
        }


        {/* Mobile Intro */}
        {/* <View style={{ height: 400, display: 'flex' }} onLayout={ (event) => { this.setState({ aboutPosition: event.nativeEvent.layout.y }) } }>    
          <View style={{ flex: 1, alignItems: 'center', flexDirection: 'column', justifyContent: 'center', marginLeft: 30, marginRight: 30 }}>
            <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 20, fontWeight: "700", color: '#999999', letterSpacing: -.5 }}>You can expect a fast offer (often just minutes), a respectful negotiation, and a safe local transaction.</Text>
          </View>
        </View> */}

        {/* Three Boxes */}
        <View style={{ display: 'flex', flexDirection: isDesktop ? 'row' : 'column', margin: marginSize, marginBottom: 0 }}>

          <AspectView style={{ borderRadius: marginSize, maxHeight: 350, padding: 80, aspectRatio: isDesktop ? 1 : 'auto', backgroundColor: '#2e2549', shadowColor: "#000", shadowOffset: { width: 0, height: 12 }, shadowOpacity: 0.15, shadowRadius: 30.00, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
            <Text style={{ fontFamily: "soleil", fontSize: 28, fontWeight: "900", color: '#bfb3e4', letterSpacing: -.5 }}>Share</Text>
            <View style={{ height: 15 }} />
            <Text style={{ textAlign: 'center', fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: '#efefef', letterSpacing: -.5 }}>Tell us what you're selling and we'll send you an offer via email.</Text>
          </AspectView>

          <View style={{ width: marginSize }} />

          <AspectView style={{ borderRadius: marginSize, maxHeight: 350, padding: 80, aspectRatio: isDesktop ? 1 : 'auto', backgroundColor: '#6a7fbc', shadowColor: "#000", shadowOffset: { width: 0, height: 12 }, shadowOpacity: 0.15, shadowRadius: 30.00, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
            <Text style={{ fontFamily: "soleil", fontSize: 28, fontWeight: "900", color: 'white', letterSpacing: -.5 }}>Review</Text>
            <View style={{ height: 15 }} />
            <Text style={{ textAlign: 'center', fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: 'white', letterSpacing: -.5 }}>If our offer looks good, select one of our pre-screened locations and a time to meet.</Text>
          </AspectView>

          <View style={{ width: marginSize }} />

          <AspectView style={{ borderRadius: marginSize, maxHeight: 350, padding: 80, aspectRatio: isDesktop ? 1 : 'auto', backgroundColor: 'white', shadowColor: "#000", shadowOffset: { width: 0, height: 12 }, shadowOpacity: 0.15, shadowRadius: 30.00, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1, overflow: 'visible' }}>
            <Text style={{ fontFamily: "soleil", fontSize: 28, fontWeight: "900", color: '#6a7fbd', letterSpacing: -.5 }}>Collect</Text>
            <View style={{ height: 15 }} />
            <Text style={{ textAlign: 'center', fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: '#a4a9c1', letterSpacing: -.5 }}>We'll meet you at the location and pay cash for your items.</Text>
          </AspectView>

        </View>

        {/* Quick Explanation */}
        <View style={{ height: 400, display: 'flex', margin: marginSize, borderRadius: marginSize, backgroundColor: '#f8f8f8', ...shadowStyle }} onLayout={ (event) => { this.setState({ aboutPosition: event.nativeEvent.layout.y }) } }>
          
          <View style={{ flex: 1, alignItems: 'center', flexDirection: 'column', justifyContent: 'center', marginLeft: 30, marginRight: 30 }}>
            {/* <ImageBackground source={ require('../assets/handshake.jpg') } resizeMode='cover' style={{ flex: 1, alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}> */}
              <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 20, fontWeight: "700", color: '#999999', letterSpacing: -.5 }}>Unlike Ebay and Craigslist, you sell directly to us.  In return, we provide a reliable, turn-key sales experience.</Text>
            {/* </ImageBackground> */}
          </View>
          {/* <View style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'black', opacity: 0.3 }} /> */}
        </View>

        {/* Learn More Section */}
        {/* <View style={{ position: 'relative', flex: 1.5, backgroundColor: '#fafafa', zIndex: 10000, ...shadowStyle, overflow: 'visible', display: 'flex', flexDirection: 'column', alignItems: 'flex-start',  padding: 80, justifyContent: 'center', borderTopWidth: 2, borderTopColor: 'white' }}>
          <Text style={{ fontFamily: "soleil", fontSize: 22, fontWeight: "800", color: '#888888', letterSpacing: -.5 }}>Learn More</Text>
          <View style={{ height: 5 }} />
          <Text style={{ fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: '#aaaaaa', letterSpacing: -.5 }}>Why are you interested in selling used items?</Text> */}

          {/* Learn how to stay safe, healthy, and profitable while selling used things online. */}

          {/* <View style={{ height: 30 }} />

          <ScrollView horizontal={ true } style={{ display: 'flex', flexDirection: 'row', alignSelf: 'stretch' }}>

            <TouchableOpacity style={{ backgroundColor: '#f6edff', borderRadius: 10, width: 200, height: 200, padding: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', ...shadowStyle }} onPress={ () => this.moreInfoClicked("Declutter") }>
              <Icon name="broom" type="material-community" color="#888888" size={ 20 } />
              <View style={{ width: 5 }} />
              <Text style={{ fontFamily: "soleil", fontSize: 18, fontWeight: "800", color: '#888888', letterSpacing: -.5 }}>Declutter</Text>
            </TouchableOpacity>

            <View style={{ width: 20 }} />
            <TouchableOpacity style={{ backgroundColor: '#edffee', borderRadius: 10, width: 200, height: 200, padding: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', ...shadowStyle }} onPress={ () => this.moreInfoClicked("Extra Income") }>
              <Icon name="cash" type="material-community" color="#888888" size={ 20 } />
              <View style={{ width: 5 }} />
              <Text style={{ fontFamily: "soleil", fontSize: 18, fontWeight: "800", color: '#888888', letterSpacing: -.5 }}>Extra Income</Text>
            </TouchableOpacity>

            <View style={{ width: 20 }} />
            <TouchableOpacity style={{ backgroundColor: '#fff7ed', borderRadius: 10, width: 200, height: 200, padding: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', ...shadowStyle }} onPress={ () => this.moreInfoClicked("Moving") }>
              <Icon name="truck" type="material-community" color="#888888" size={ 20 } />
              <View style={{ width: 5 }} />
              <Text style={{ fontFamily: "soleil", fontSize: 18, fontWeight: "800", color: '#888888', letterSpacing: -.5 }}>Moving</Text>
            </TouchableOpacity>

            <View style={{ width: 20 }} />
            <TouchableOpacity style={{ backgroundColor: '#edfbff', borderRadius: 10, width: 200, height: 200, padding: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', ...shadowStyle }} onPress={ () => this.moreInfoClicked("Business") }>
              <Icon name="business" type="ionicons" color="#888888" size={ 20 } />
              <View style={{ width: 5 }} />
              <Text style={{ fontFamily: "soleil", fontSize: 18, fontWeight: "800", color: '#888888', letterSpacing: -.5 }}>Business</Text>
            </TouchableOpacity>

            <View style={{ width: 20 }} />
            <TouchableOpacity style={{ backgroundColor: '#edfbff', borderRadius: 10, width: 200, height: 200, padding: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', ...shadowStyle }} onPress={ () => this.moreInfoClicked("One-Off") }>
              <Icon name="diamond-stone" type="material-community" color="#888888" size={ 20 } />
              <View style={{ width: 5 }} />
              <Text style={{ fontFamily: "soleil", fontSize: 18, fontWeight: "800", color: '#888888', letterSpacing: -.5 }}>One-Off</Text>
            </TouchableOpacity>

            <View style={{ width: 20 }} />
            <TouchableOpacity style={{ backgroundColor: 'white', borderRadius: 10, width: 200, height: 200, padding: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', ...shadowStyle }} onPress={ () => this.moreInfoClicked("Other") }>
              <Icon name="more-vert" type="material" color="#888888" size={ 20 } />
              <View style={{ width: 5 }} />
              <Text style={{ fontFamily: "soleil", fontSize: 18, fontWeight: "800", color: '#888888', letterSpacing: -.5 }}>Other</Text>
            </TouchableOpacity>
          </ScrollView>
        </View> */}

      {/* Sign Up */}
      <View style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden' }} onLayout={ (event) => { this.setState({ signUpPosition: event.nativeEvent.layout.y }) } }>

        {
          isDesktop ?
            <View style={{ flex: 2, backgroundColor: '#555555' }}>
              <View style={{ backgroundColor: 'black', opacity: 0.1, width: "100%", height: "100%", position: 'absolute', zIndex: 2 }} />
              <ImageBackground style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 1 }} source={ require("../assets/meeting.jpg") } resizeMode="cover" />
            </View> :
            null
        }

        <View style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', backgroundColor: '#6a7fbc', paddingVertical: 100, paddingHorizontal: 50 }}>
          <Text style={{ fontFamily: "soleil", fontSize: 28, fontWeight: "900", color: 'white', letterSpacing: -.5 }}>Sign Up</Text>
          <View style={{ height: 5 }} />
          <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 16, fontWeight: "600", color: '#f8f8f8', letterSpacing: -.5 }}>A representative will contact you via email.</Text>

          <View style={{ height: 30 }} />

          {/* TODO:  Hide border on focus. */}
          <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 16, fontWeight: "500", color: '#f8f8f8', letterSpacing: -.5 }}>First Name</Text>
          <View style={{ height: 5 }} />
          <TextInput value={ this.state.signUpFormState.name } style={{ fontFamily: "soleil", fontWeight: "800", marginTop: 3, marginBottom: 3, backgroundColor: 'transparent', color: "white", fontSize: 14, height: 30, alignSelf: 'stretch', borderColor: '#DCDCDC', borderBottomWidth: 2 }} onChangeText={ (text) => this.setSignUpForm({ name: text }) } />

          <View style={{ height: 20 }} />

          {/* TODO:  Hide border on focus. */}
          <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 16, fontWeight: "500", color: '#f8f8f8', letterSpacing: -.5 }}>Email</Text>
          <View style={{ height: 5 }} />
          <TextInput value={ this.state.signUpFormState.email } style={{ fontFamily: "soleil", fontWeight: "800", marginTop: 3, marginBottom: 3, backgroundColor: 'transparent', color: "white", fontSize: 14, height: 30, alignSelf: 'stretch', borderColor: '#DCDCDC', borderBottomWidth: 2 }} onChangeText={ (text) => this.setSignUpForm({ email: text }) } />

          <View style={{ height: 20 }} />

          <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 16, fontWeight: "500", color: '#f8f8f8', letterSpacing: -.5 }}>Join our mailing list?</Text>
          <View style={{ height: 5 }} />
          <Switch value={ this.state.signUpFormState.joinMailing } onValueChange={ () => this.setSignUpForm({ joinMailing: !this.state.signUpFormState.joinMailing }) } trackColor={ '#aaaaaa' as any }  { ...{ activeTrackColor: '#f8f8f8', activeThumbColor: '#dddddd' }} thumbColor='#dddddd' style={{ height: 30 }} />

          <TouchableOpacity disabled={ !this.isSignUpFormValid() } onPress={ () => { this.isSignUpFormValid() ? this.submitSignUpForm() : null } } style={{ backgroundColor: 'white', opacity: this.isSignUpFormValid() ? 1 : 0.2, borderRadius: 20, shadowColor: "#000", shadowOffset: { width: 0, height: 12 }, shadowOpacity: 0.15, shadowRadius: 30.00, display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 200, height: 40, overflow: 'visible', alignItems: 'center', alignSelf: 'flex-end' }}>
            <Text style={{ textAlign: 'left', fontFamily: "soleil", fontSize: 16, fontWeight: "800", color: '#555555', letterSpacing: -.5 }}>Submit</Text>
          </TouchableOpacity>
          
          {/* <TouchableOpacity onPress={ () => this.pickImage("One") } style={{ flex: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            { this.state.submitFormState.imageOne  ? 
              <ImageBackground source={{ uri: this.state.submitFormState.imageOne }} style={{ flex: 1 }} resizeMode='cover' /> :
              <Icon name="photo" type="material" size={ 50 } color="#D5D5D5" />
            }
          </TouchableOpacity> */}
        </View>
      </View>

        
        {/* Footer */}
        <View style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', backgroundColor: '#444444', justifyContent: 'center', padding: 30 }}>
          <Text style={{ fontFamily: "soleil", fontSize: 14, fontWeight: "900", color: '#bfb3e4', letterSpacing: -.5 }}>PopSale</Text>
          <View style={{ height: 5 }} />
          <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 12, fontWeight: "600", color: 'white', letterSpacing: -.5 }}>Copyright 2020</Text>
        </View>

      </View>);
  }

  public render() {
    
    const isDesktop = this.props.sizes.isDesktop;

    return (
      <Router>

        <Route exact path="/" component={ this.App } />
        <Route exact path="/create" component={ IntakeForm } />
      </Router>
    );
    
  }
}

export interface SizeProp {
  sizes: {
    isDesktop: boolean,
    isTablet: boolean,
    isMobile: boolean
  }
}

export const withSizes = (Component: any) => {
  return () => {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const isTablet = useMediaQuery({ minWidth: 500, maxWidth: 767 });
    const isMobile = useMediaQuery({ maxWidth: 499 });
    const sizes = {
      isDesktop,
      isTablet,
      isMobile
    }
    return <Component sizes={ sizes } />;
  }
}

export const AppComponent = withSizes(AppComponentBase);
