import { AppLoading } from 'expo';
import * as React from "react";
import { StatusBar } from 'react-native';
import "react-native-gesture-handler";
import { AppComponent } from "./src/App";

console.disableYellowBox = true;


export default class NativeApp extends React.Component {
  state = {
    fontsAreLoaded: false,
  };

  async componentWillMount() {
    this.setState({ fontsAreLoaded: true });
  }

  render() {
    if (!this.state.fontsAreLoaded) {
      return <AppLoading />;
    }
    return (
      <React.Fragment>
        <StatusBar hidden={ false } />
        <AppComponent />
      </React.Fragment>
    );
  }
}
