import * as React from 'react';
import { View } from 'react-native';
import Markdown from 'react-native-markdown-renderer';
import { mdStyles } from './App';
var zipcodes = require('zipcodes');

export const TOS = ({  }: {  }) => {

  const copy = `
  Terms of Service
  ================
  
  Last revised on Oct. 26, 2020
  
  ### The Gist
  
  PopSale operates the Service, which we hope you use. If you use it, please use it responsibly.
  
  That's the basic idea, but You must read through the entire Terms of Service below and agree with all the details before You use the Service.
  
  ### Reuse
  
  This document is an adaptation of the [Heroku Terms of Service](http://legal.heroku.com/tos), which is turn an adaptation of the [Google App Engine Terms of Service](http://code.google.com/appengine/terms.html). The original work has been modified with permission under the [Creative Commons Attribution 3.0 License](http://creativecommons.org/licenses/by/3.0/). Neither Heroku, Inc. nor Google, Inc. is not connected with and does not sponsor or endorse PopSale or its use of the work.
  
  You're welcome to adapt and use this document for your own needs. If you make an improvement, we'd appreciate it if you would let us know so we can consider improving our own document.
  
  ### Your Agreement with PopSale
  
  Your use of the PopSale service is governed by this agreement (the "Terms"). "PopSale" is a Sole Proprietorship based in westerrn Massachusetts. The "Service" includes all services PopSale makes available including but not limited to the web sites [popsale.co], [www.popsale.co], our blog, our API, and any other software, sites, and / or services offered by PopSale. "Content" means all content generated by PopSale on your behalf (including metric data).
  
  In order to use the Service, You (the "Customer", "You", or "Your") must first agree to the Terms. You understand and agree that PopSale will treat Your use of the Service as acceptance of the Terms from that point onwards.
  
  PopSale may make changes to the Terms from time to time. You may reject the changes by terminating Your account. You understand and agree that if You use the Service after the date on which the Terms have changed, PopSale will treat Your use as acceptance of the updated Terms.
  
  If you have any question about the Terms, please contact us at support@popsale.co
  
  ### Your Account
  
  * You may not use the Service if You are a person barred from receiving the Service under the laws of the United States or other countries, including the country in which You are resident or from which You use the Service.
  * You may not use the service unless you are over the age of 18.
  * You must be a human. Account created by automated methods are not permitted.
  
  ### Use of the Service
  
  * Your use of the Service must comply with all applicable laws, regulations and ordinances.
  * You agree to not engage in any activity that interferes with or disrupts the Service.
  * PopSale reserves the right to enforce quotas and usage limits (to any resources, including the API) at its sole discretion, with or without notice, which may result in PopSale disabling or throttling your usage of the Service for any amount of time.
  
  ### Service Policies and Privacy
  
  The Service shall be subject to the privacy policy for the Service. You agree to the use of Your data in accordance with PopSale's privacy policies.
  
  ### Service Disclaimer
  
  * You acknowledge that offers made to You via the Services may be below market value, and PopSale may make a profit from items sold to us.
  * You agree that it is Your responsibility to determine whether or not any offer made to You is satisfactory.
  * You acknowledge that you are under no obligation to accept an offer made to You via the Service.
  
  * The Service may be provided to You without charge up with certain limits or for a certain "trial" period of time.
  * Usage over this limit (or after the "trial" period) or requires Your purchase of additional resources or services.
  * For all purchased resources and services, we will charge Your credit card on a monthly basis.
  * PopSale may change its fees and payment policies for the Service by updating this "Terms of Service" document.
  
  ### Cancellation and Termination

  * You agree that PopSale, in its sole discretion and for any or no reason, may terminate or suspend Your use of the Service. You agree that any termination of Your access to the Service may be without prior notice, and You agree that PopSale will not be liable to You or any third party for such termination.
  
  ### Customer Content
  
  * PopSale claims no ownership or control over any Customer Content (except Feedback). You retain copyright and any other rights You already hold in the Customer Content and You are responsible for protecting those rights, as appropriate.
  * You agree to assume full responsibility for configuring the Service to allow appropriate access to any Customer Content provided to the Service as applicable.
  
  ### Feedback
  
  You may choose to or we may invite You to submit comments or ideas about the Service, including but not limited to ideas about improving the Service or our products ("Ideas"). By submitting any Idea, You agree that Your disclosure is unsolicited and without restriction and will not place PopSale under any fiduciary or other obligation, and that we are free to use the Idea without any additional compensation to You, and/or to disclose the Idea on a non-confidential basis or otherwise to anyone.  Further, you assign to PopSale all assignable rights in the Idea.  When assignment is not possible, you give PopSale a worldwide, royalty-free, non-revocable, assignable, and non-exclusive license to any and all assignable rights in the aforementioned feedback.
  
  ### Modification of the Service
  
  * You acknowledge and agree that the Service may change from time to time without prior notice to You.
  * Changes include, without limitation, changes to fee and payment policies, security patches, added or removed functionality, and other enhancements or restrictions.
  * PopSale shall not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Service.
  
  ### External Resources
  
  The Service may include hyperlinks to other web sites or content or resources or email content. You acknowledge and agree that PopSale is not responsible for the availability of any such external sites or resources, and does not endorse any advertising, products or other materials on or available from such web sites or resources.
  
  ### License from PopSale and Restrictions
  
  PopSale gives You a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to You by PopSale as part of the Service as provided to You by PopSale. This license is for the sole purpose of enabling You to use and enjoy the benefit of the Service as provided by PopSale, in the manner permitted by the Terms.
  
  You may not (and You may not permit anyone else to): (a) copy, modify, create a derivative work of, reverse engineer, decompile or otherwise attempt to extract the source code of the Service or any part thereof, unless this is expressly permitted or required by law, or unless You have been specifically told that You may do so by PopSale, in writing (e.g., through an open source software license); or (b) attempt to disable or circumvent any security mechanisms used by the Service.
  
  Open source software licenses for components of the Service released under an open source license constitute separate written agreements. To the limited extent that the open source software licenses expressly supersede these Terms, the open source licenses govern Your agreement with PopSale for the use of the components of the Service released under an open source license.
  
  ### Exclusion of warranties
  
  * You expressly understand and agree that your use of the service is at your sole risk and that the service is provided "as is" and "as available.".
  * You agree that PopSale has no responsibility or liability for the deletion or failure to store any Content and other communications maintained or transmitted through use of the Service. You further acknowledge that You are solely responsible for securing and backing up Customer Content.
  * PopSale does not warrant to you that: (a) your use of the service will meet your requirements, (b) your use of the service will be uninterrupted, timely, secure or free from error, (c) the results or data provided by the Service will be accurate, (d) the quality of the service will meet your expectations and (e) any errors in the Service will be fixed.
  
  ### Limitation of Liability
  
  You expressly understand and agree that PopSale, its subsidiaries and affiliates, and its licensors shall not be liable to you for any direct, indirect, incidental, special consequential or exemplary damages which may be incurred by you, however caused and under any theory of liability. This shall include, but not be limited to, any loss of profit (whether incurred directly or indirectly), any loss of goodwill or business reputation, any loss of data suffered, cost of procurement of substitute goods or services, or other intangible loss (whether or not PopSale has been advised of or should have been aware of the possibility of any such losses arising).
  
  ### Indemnification
  
  You agree to hold harmless and indemnify PopSale, and its subsidiaries, affiliates, officers, agents, employees, advertisers, licensors, suppliers or partners (collectively "PopSale and Partners") from and against any third party claim arising from or in any way related to (a) Your breach of the Terms, (b) Your use of the Service, (c) Your violation of applicable laws, rules or regulations in connection with the Service, or (d) Your Customer Content, including any liability or expense arising from all claims, losses, damages (actual and consequential), suits, judgments, litigation costs and attorneys' fees, of every kind and nature. In such a case, PopSale will provide You with written notice of such claim, suit or action.
  
  ### General Legal Terms
  
  The Terms constitute the whole legal agreement between You and PopSale and govern Your use of the Service and completely replace any prior agreements between You and PopSale in relation to the Service.
  
  You agree that if PopSale does not exercise or enforce any legal right or remedy which is contained in the Terms (or which PopSale has the benefit of under any applicable law), this will not be taken to be a formal waiver of PopSale's rights and that those rights or remedies will still be available to PopSale.
  
  PopSale shall not be liable for failing or delaying performance of its obligations resulting from any condition beyond its reasonable control, including but not limited to, governmental action, acts of terrorism, earthquake, fire, flood or other acts of God, labor conditions, power failures, and Internet disturbances.
`;

  return (
    <View>
      <Markdown style={ mdStyles }>{copy}</Markdown>
    </View>
  );
}